<template>
    <div>
        <div id="report" class="page" v-if="dataReport">
            <div>
                <div>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>{{$t('daily_brief_report_invoices.date')}}</th>
                            <th>{{$t('daily_brief_report_invoices.paid')}} ({{currencyName}})</th>
                            <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currencyName}})</th>
                            <th>{{$t('daily_brief_report_invoices.refund')}} ({{currencyName}})</th>
                            <th>{{$t('daily_brief_report_invoices.total')}} ({{currencyName}})</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, index) in dataReport" :key="'dataReport'+index">
                            <td>{{row.label}}</td>
                            <td>{{row.paid}}</td>
                            <td>{{row.unpaid}}</td>
                            <td>{{row.refund}}</td>
                            <td>{{row.total}}</td>
                        </tr>
                        <tr v-for="(row, index) in dataReport" :key="'dataReport22'+index">
                            <td>{{row.label}}</td>
                            <td>{{row.paid}}</td>
                            <td>{{row.unpaid}}</td>
                            <td>{{row.refund}}</td>
                            <td>{{row.total}}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="4">{{$t('daily_brief_report_invoices.total')}}</td>
                            <th>{{totalSum}}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {jsPDF} from "jspdf";
    import html2canvas from "html2canvas";

    export default {
        name: "SummaryPdf",
        data(){
            return{
                mainRoute: '/dashboard',
                dataReport:null,
                currencyName:null,
                totalSum:null,
            }
        },
        methods:{
            exportPdf() {
                let element = document.getElementById('report');
                // let width = element.clientWidth - 110;
                // let height = element.clientHeight;

                let pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'pt',
                    format: "A4"
                });
                html2canvas(element).then(canvas => {
                    let image = canvas.toDataURL('image/png', 3.0);
                    pdf.addImage(image, 'PNG', 1, 1);
                    // console.log(element);
                    // console.log(element.innerHTML);
                    // pdf.html(element,{
                    //     callback: function(doc) {
                    //         doc.save('report'+'.pdf');
                    // }});
                    pdf.save('report'+'.pdf');
                    // if(this.type == 'print'){
                    //     pdf.autoPrint();
                    // }
                    // let _url = pdf.output("bloburl");
                    // location.href = _url;
                });
            },
            async getData() {
                await ApiService.get(`${this.mainRoute}/invoice/report_invoice_list`, {params: {...this.filters}}).then((response) => {
                    this.dataReport = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total ? parseFloat(row.total) : 0;
                    });
                    this.totalSum = _total_sum.toFixed(2);
                });
            },
            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.currencyName = response.data.data.currency_name;
                });
            },
        },
        mounted() {
            let promise = this.getData();
            let promise2 = this.getAuthUser();
            Promise.all([promise, promise2]).then(()=>{
                this.exportPdf();
            });
        }
    }
</script>

<style scoped>
    .page {
        width: 535px;
        min-height: 297mm;
        padding: 5mm;
        background: white;
    }
</style>